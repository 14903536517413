

















import Vue from 'vue'

export default Vue.component('Error', {
    components: {
        QTranslate: () => import('../../components/elements/QTranslate.vue'),
        QAlert: () => import('../../components/elements/QAlert.vue'),
    },
    props: {
        message: {
            type: String,
            required: false,
        }
    },
    computed: {
        message2() {
            return this.$route.query.message;
        }
    }
})
